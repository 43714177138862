import { Component, EventEmitter, OnInit, Output } from '@angular/core';


@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {
  @Output() sidenavLayoutToggle = new EventEmitter<boolean>();
  constructor() { }

  ngOnInit() {
  }
  openMenu = true;
  clickMenu() {
    this.openMenu = !this.openMenu;
    this.sidenavLayoutToggle.emit(this.openMenu);
  }
}
