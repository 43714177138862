<section fxLayout.gt-sm="80" fxLayoutAlign.gt-sm="center center">
    <mat-card >
        <mat-card-content>
            <p>Your account is not currently active. Please contact BuckUp customer service.</p>
            <p>
                <button mat-flat-button color="accent" routerLink="/login">Back to login Page</button>
            </p>
        </mat-card-content>
    </mat-card>
</section>
