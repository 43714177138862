<mat-toolbar color="primary" *ngIf="checkLogin()">
  <div>
    <button mat-icon-button (click)="onToggleSidenav()">
      <mat-icon>menu</mat-icon>
    </button>
  </div>
  <div>
    <a routerLink="/"><img [ngStyle]="{'width': '180px', 'padding-top': '10px'}" src="assets/buckup-logo-white.png"></a>
  </div>
  <div fxFlex fxLayout fxLayoutAlign="flex-end" fxHide.xs>
    <ul fxLayout fxLayoutGap="10px" class="nagivation-items">
      <li>
        <button *ngIf="checkLogin()" mat-icon-button>
          <mat-icon>notifications</mat-icon>
        </button>
      </li>
      <li>
        <button *ngIf="checkAdminLogin()" routerLink="/admin" mat-icon-button>
          <mat-icon>admin_panel_settings</mat-icon>
        </button>
      </li>
      <li *ngIf="checkLogin()"><a style="cursor: pointer;" (click)="onLogout()">Logout</a></li>
    </ul>
  </div>
</mat-toolbar>