import { Injectable } from '@angular/core';
import { MatDrawerMode, MatSidenav } from '@angular/material/sidenav';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class SidenavService {
    constructor() { }
    private sidenav!: MatSidenav;

    public setSidenav(sidenav: MatSidenav) {
        this.sidenav = sidenav;
    }

    public open() {
        this.sidenav.opened = true;
        return this.sidenav.open();
    }

    public close() {
        return this.sidenav.close();
    }

    public toggle() {
        return this.sidenav.toggle();
    }
    switchMode(val: MatDrawerMode) {
        this.sidenav.mode = val;
    }
    getMode(): MatDrawerMode {
        return this.sidenav.mode;
    }
}