import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { initializeApp,provideFirebaseApp } from '@angular/fire/app';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { environment } from '../environments/environment';
import { provideAuth,getAuth } from '@angular/fire/auth';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material.module';
import { HeaderComponent } from './features/layout/header/header.component';
import { MainComponent } from './features/layout/main/main.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { SidenaveComponent } from './features/layout/sidenav/sidenav.component';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { SpinnerService } from './core/services/spinner.service';
import { NotifyAccountComponent } from './features/notify/account/notify.account.component';
import { provideFunctions,getFunctions } from '@angular/fire/functions';
import { SidenavService } from './core/services/sidenav.service';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    MainComponent,
    SidenaveComponent,
    NotifyAccountComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAuth(() => getAuth()),
    provideFirestore(() => getFirestore()),
    provideFunctions(() => getFunctions()),
    BrowserAnimationsModule,
    MaterialModule,
    FlexLayoutModule
  ],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { showError: true }
    },
    SpinnerService,
    SidenavService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
