import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AuthService } from 'src/app/core/services/auth.service';


@Component({
  selector: 'app-notify-account',
  templateUrl: './notify.account.component.html',
  styleUrls: ['./notify.account.component.scss']
})
export class NotifyAccountComponent {
    
}
