// For BuckUp dev
export const environment = {
    production: true,
    firebase: {
        apiKey: "AIzaSyC3xH6MFQ-3MDLFegJJKi4giFM6Gr5AK5s",
        authDomain: "buckup-dev-1b5d5.firebaseapp.com",
        projectId: "buckup-dev-1b5d5",
        storageBucket: "buckup-dev-1b5d5.appspot.com",
        locationId: 'us-central1',
        messagingSenderId: "28609871448",
        appId: "1:28609871448:web:13d3af4a99b323cdc6942d",
        measurementId: "G-WKST1SLDLV"
    }
};