import {
  Auth,
  GoogleAuthProvider,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  User,
  user,
  authState,
  IdTokenResult,
  getIdTokenResult
} from '@angular/fire/auth';

import { inject, Injectable } from '@angular/core';
import { LoginData } from '../interfaces/login-data.interface';
import { Subject, Subscription } from 'rxjs';



@Injectable({
  providedIn: 'root',
})
export class AuthService {
  currentUser$ = new Subject<User | null>();
  constructor(private auth: Auth) {
    this.initialize();
  }

  private initialize() {
    this.auth.onAuthStateChanged(user => {
      if (user) {
        this.currentUser$.next(user);
      }
    });

  }
  login({ email, password }: LoginData) {
    return signInWithEmailAndPassword(this.auth, email, password);
  }

  loginWithGoogle() {
    return signInWithPopup(this.auth, new GoogleAuthProvider());
  }

  register({ email, password }: LoginData) {
    return createUserWithEmailAndPassword(this.auth, email, password);
  }

  logout() {
    return signOut(this.auth);
  }

  isLoggedIn() {
    return this.auth.currentUser;
  }

  getClaims() {
    return this.auth.currentUser?.getIdTokenResult();
  }
  getCurrentUser() {
    return this.auth.currentUser;
  }
  getUserClaims(user: User) {
    return getIdTokenResult(user);
  }
}