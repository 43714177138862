<mat-sidenav-container>
    <mat-sidenav #sidenav role="navigation" [opened]="sidenavLayout" mode="side">
      <mat-nav-list>
        <a mat-list-item routerLink="dashboard">
          <mat-icon>dashboard</mat-icon>
          <span class="nav-caption">Dashboard</span>
        </a>
        <a mat-list-item routerLink="locker">
          <mat-icon>vpn_key</mat-icon>
          <span class="nav-caption">Locker</span>
        </a>
      </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content>
      <mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
      <main [ngStyle]="{'padding': '10px;'}">
        <router-outlet></router-outlet>
      </main>
    </mat-sidenav-content>
  </mat-sidenav-container>