import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth.service';
import { SidenavService } from 'src/app/core/services/sidenav.service';
import { SpinnerService } from 'src/app/core/services/spinner.service';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Output() sidenavToggle = new EventEmitter<void>();
  access_level = 0;
  constructor(private authService: AuthService,
    private router: Router,
    private spinner: SpinnerService, private sideNavService: SidenavService) {
    this.spinner.loadingStateChanged.next(false);
  }

  async ngOnInit(): Promise<void> {
    this.authService.currentUser$.subscribe((u: any) => {
      this.authService.getUserClaims(u).then(c => {
        this.access_level = c.claims['access_level'];
      });
    })
    
  }

  onToggleSidenav() {
    this.sidenavToggle.emit();
  }
  checkLogin(): boolean {
    if (this.authService.isLoggedIn()) {
      return true;
    } else {
      return false;
    }
  }
  checkAdminLogin(): boolean {
    if (this.access_level >= 5) {
      return true;
    } else {
      return false;
    }
  }
  onLogout() {
    this.authService
      .logout()
      .then(() => {
        this.sideNavService.close();
        this.router.navigate(['login'])
      })
      .catch((e) => console.log(e.message));
  }
}
