import { Component, EventEmitter, OnDestroy, OnInit, Input, Output, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { AuthService } from 'src/app/core/services/auth.service';
import { Subscription } from 'rxjs';
import { SpinnerService } from 'src/app/core/services/spinner.service';
import { SidenavService } from 'src/app/core/services/sidenav.service';
import { MediaObserver } from '@angular/flex-layout';

@Component({
    selector: 'app-sidenav',
    templateUrl: './sidenav.component.html',
    styleUrls: ['./sidenav.component.scss']
})
export class SidenaveComponent implements OnInit, OnDestroy {
    @ViewChild('sidenav', { static: true }) sidenav!: MatSidenav;
    @Input() sidenavLayout: any;
    isLoading = false;
    private loadingSubs!: Subscription;
    @Output() sidenavToggle = new EventEmitter<void>();
    private mediaSubscription!: Subscription;
    constructor(private authService: AuthService,
        private spinner: SpinnerService,
        private sideNavService: SidenavService,
        private mediaObserver: MediaObserver) { }

    ngOnInit(): void {
        this.sideNavService.setSidenav(this.sidenav);
        this.loadingSubs = this.spinner.loadingStateChanged.subscribe(isLoading => {
            this.isLoading = isLoading;
        });

        this.mediaSubscription = this.mediaObserver
            .asObservable()
            .subscribe((change) => {
                change.forEach((item) => {
                    if (this.checkLogin() === false) {
                        this.sideNavService.close();
                    } else {
                        if (item.mqAlias === 'xs' || item.mqAlias === 'sm') {
                            this.sideNavService.switchMode('over');
                            this.sideNavService.close();
                        } else if (item.mqAlias === 'gt-sm') {
                            this.sideNavService.switchMode('side');
                            this.sideNavService.open();
                        } else if (item.mqAlias === "lt-sm") {
                            this.sideNavService.close();
                        }
                    }
                });
            });
    }
    checkLogin(): boolean {
        if (this.authService.isLoggedIn()) {
            return true;
        } else {
            return false;
        }
    }
    onLogout() {
        this.authService.logout();
    }
    ngOnDestroy(): void {
        this.loadingSubs.unsubscribe();
        this.mediaSubscription.unsubscribe();
    }
}
