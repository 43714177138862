import { RouterModule, Routes } from '@angular/router';
import {
  canActivate,
  hasCustomClaim,
  redirectLoggedInTo,
  redirectUnauthorizedTo,
} from '@angular/fire/auth-guard';

import { NgModule } from '@angular/core';
import { NotifyAccountComponent } from './features/notify/account/notify.account.component';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['']);
const redirectLoggedInToHome = () => redirectLoggedInTo(['dashboard']);
const adminOnly = () => hasCustomClaim("admin");
const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./features/auth/auth.module').then((m) => m.AuthModule),
    ...canActivate(redirectLoggedInToHome),
  },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('./features/dashboard/dashboard.module').then(
        (m) => m.DashboardModule
      ),
    ...canActivate(redirectUnauthorizedToLogin),
  },
  {
    path: 'notifyaccount',
    component: NotifyAccountComponent
  },
  {
    path: 'locker',
    loadChildren: () => import('./features/locker/locker.module').then(
      (m) => m.LockerModule),
    ...canActivate(redirectUnauthorizedToLogin),
  },
  {
    path: 'admin',
    loadChildren: () => import('./features/admin/admin.module').then(
      m => m.AdminModule),
      ...canActivate(adminOnly),
  },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }